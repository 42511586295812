<template>
  <div>
    <v-container
      fluid
      class="py-0"
    >
      <v-row class="mt-3">
        <v-col
          cols="6"
          class="pt-0 pb-2"
        >
          <v-row>
            <v-col
              cols="6"
              class="pt-0 pb-0"
            >
              <v-menu
                v-model="selectDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                max-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedDate"
                    :label="$t('date')"
                    outlined
                    hide-details
                    dense
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    style="border-left: 0; border-right: 0;"
                  />
                </template>
                <v-date-picker
                  v-model="selectedDate"
                  no-title
                  @input="selectDate = false"
                  style="height: 350px;"
                />
              </v-menu>
            </v-col>
            <v-col
              class="pt-0 pb-0 pr-0"
              style="max-width: 80px;"
            >
              <v-btn
                tile
                block
                outlined
                style="border-right: 0;"
              >
                <v-icon
                  large
                  color="green darken-2"
                  @click="prevDay"
                  :disabled="isGettingBookings"
                >
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              class="pt-0 pb-0 pl-0"
              style="max-width: 80px;"
            >
              <v-btn
                tile
                block
                outlined
                style="border-left: 0;"
              >
                <v-icon
                  large
                  color="green darken-2"
                  @click="nextDay"
                  :disabled="isGettingBookings"
                >
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          class="text-right pt-0 pb-0"
        >
          <v-btn
            dark
            color="green"
            class="mr-2"
            @click="createBooking"
          >
            {{ $t('addBooking') }}
          </v-btn>
          <v-btn
            outlined
            @click="createBooking"
          >
            {{ $t('walkIn') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-select
            dense
            hide-details
            :label="$t('items')"
            outlined
            v-model="selectedItems"
            :items="eventItems"
            item-text="title"
            item-value="uuid"
            multiple
          />
        </v-col>
        <v-col cols="2">
          <v-select
            dense
            hide-details
            :label="$t('units')"
            outlined
            v-model="selectedLocationVariations"
            :items="locationVariationsList"
            item-text="title"
            item-value="id"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item
                @click="toggleAllLocationVariations"
                selectable
              >
                <v-list-item-action>
                  <v-checkbox
                    :value="Array.isArray(locationVariationsList) && (locationVariationsList.length === selectedLocationVariations.length || locationVariationsList.length === 0)"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  {{ $t('all') }}
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
            <template v-slot:selection="{index}">
              <span v-if="Array.isArray(locationVariationsList) && index === 0 && (locationVariationsList.length === selectedLocationVariations.length || locationVariationsList.length === 0)">
                {{ $t('all') }}
              </span>
              <span
                v-else-if="index === 0"
              >
                {{ selectedLocationVariations.length }} {{ $t('selected') }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            dense
            hide-details
            :label="$t('location')"
            outlined
            v-model="selectedLocation"
            :items="locations"
            item-text="label"
            item-value="uuid"
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @click="selectedLocation = null"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('selectAll') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            dense
            hide-details
            :label="$t('bookingState')"
            outlined
            v-model="selectedBookingState"
            :items="bookingStates"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          v-if="viewAllSettings"
          cols="2"
        >
          <v-select
            dense
            hide-details
            :label="$t('timeRange')"
            outlined
            v-model="selectedTimeRange"
            :items="timeRanges"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          v-if="viewAllSettings"
          cols="2"
        >
          <v-select
            dense
            hide-details
            :label="$t('slotSize')"
            outlined
            v-model="selectedSlotSize"
            :items="slotSizes"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            text
            class="pa-0"
            color="primary"
            @click="viewAllSettings = !viewAllSettings"
          >
            <span v-if="viewAllSettings">- {{ $t('hide') }}&nbsp;</span>
            <span v-else>+ {{ $t('more') }}&nbsp;</span>
            {{ $t('settings') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-left caption pt-1 pb-1">
          {{ bookingsCount }} {{ $t('bookings') }}
        </v-col>
        <v-col class="caption text-center pt-1 pb-1">
          {{ pax }} {{ $t('guests') }}
        </v-col>
        <v-col class="text-right pt-1 pb-1">
          <div>
            <div class="caption">
              {{ paxRedeemed }} {{ $t('checkedIn') }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        align-self="end"
        style="height: 100%;"
      >
        <v-col style="height: 100%; padding-bottom: 48px;">
          <FullCalendar
            style="height: 100%;"
            :key="fullCalendarKey"
            ref="fullCalendar"
            :options="calendarOptions"
          />
        </v-col>
      </v-row>
    </v-container>
    <BookingDialog
      @rebook="rebook"
      @close="selectedBooking = null"
      v-if="selectedBooking !== null && selectedBooking !== undefined"
      :booking="selectedBooking"
      v-on="$listeners"
      @overwriteBooking="overwriteBooking"
    />
    <BlockedSlotDialog
      @close="selectedBlockedSlot = null"
      v-if="selectedBlockedSlot !== null && selectedBlockedSlot !== undefined"
      :blocked-slot="selectedBlockedSlot"
      @unblockSlot="unblockSlot"
    />
    <SlotActionDialog
      v-if="selectedSlot !== null && selectedSlot !== undefined"
      @close="selectedSlot = null"
      @createBooking="createBooking"
      @blockSlot="blockSlot"
    />
    <SelectLocationDialog
      v-if="showSelectLocationDialog"
      :locations="locations"
      @close="showSelectLocationDialog = false"
      @blockSlot="blockSlot"
      @changeSelectedLocation="changeSelectedLocation"
    />
    <ConfirmEventUpdateDialog
      v-if="showConfirmEventUpdateDialog"
      :processing="processing"
      @confirm="updateEvent(eventToUpdate)"
      @cancel="cancelEventUpdate"
    />
  </div>
</template>
<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import BookingDialog from "./BookingDialog"
import SlotActionDialog from './SlotActionDialog'
import BlockedSlotDialog from './BlockedSlotDialog'
import SelectLocationDialog from "./SelectLocationDialog"
import ConfirmEventUpdateDialog from "./ConfirmEventUpdateDialog"
import moment from 'moment'

export default {
  components: {
    FullCalendar,
    BookingDialog,
    SlotActionDialog,
    BlockedSlotDialog,
    SelectLocationDialog,
    ConfirmEventUpdateDialog
  },
  props: {
    bookings: {
      type: Array,
      default: () => []
    }
  },
  data() {

    let selectedTimeRange = localStorage.getItem('slotMinTime')

    if(typeof selectedTimeRange === 'undefined' || selectedTimeRange === null) {
      selectedTimeRange = '10:00:00'
    }

    let selectedSlotSize = localStorage.getItem('slotDuration')

    if(selectedSlotSize === null || selectedSlotSize === undefined) {
      selectedSlotSize = '00:15:00'
    }

    /* // Commented out because of lack of location on bookings. We can not trust that all bookings have location.
    let selectedLocation = localStorage.getItem(this.selectedLocationKey)

    if(selectedLocation === undefined) {
        selectedLocation = null
    }
    */

    let selectedItems = []

    const selectedItemsStringified = localStorage.getItem(this.selectedItemsKey)

    if(selectedItemsStringified !== undefined && selectedItemsStringified !== null) {
      try {
        selectedItems = JSON.parse(selectedItemsStringified)
      }
      catch(e) {
        // Do nothing
      }
    }

    let selectedLocationVariations = []

    const selectedLocationVariationsStringified = localStorage.getItem(this.$store.state.organizationUUID + ':selectedLocationVariations')

    if(selectedLocationVariationsStringified !== undefined && selectedLocationVariationsStringified !== null) {
      try {
        selectedLocationVariations = JSON.parse(selectedLocationVariationsStringified)
      }
      catch(e) {
        // Do nothing
      }
    }

    return {
      selectDate: null,
      fullCalendarKey: 0,
      showAddBookingDialog: false,
      selectedBooking: null,
      bookingItem: null,
      selectedSlot: null,
      bookingTime: null,
      bookingDuration: null,
      showSelectLocationDialog: false,
      selectedItems: selectedItems,
      filteredBookings: null,
      selectedBlockedSlot: null,
      showConfirmEventUpdateDialog: false,
      eventToUpdate: null,
      processing: false,
      bookingObject: null,
      viewAllSettings: false,
      selectedLocationVariations: selectedLocationVariations,
      selectedSlotSize: selectedSlotSize,
      selectedTimeRange: selectedTimeRange,
      slotSizes: [
        {
          value: '00:05:00',
          text: this.$t('5min')
        },
        {
          value: '00:10:00',
          text: this.$t('10min')
        },
        {
          value: '00:15:00',
          text: this.$t('15min')
        },
        {
          value: '00:20:00',
          text: this.$t('20min')
        },
        {
          value: '00:30:00',
          text: this.$t('30min')
        },
        {
          value: '01:00:00',
          text: this.$t('60min')
        },
      ],
      calendarOptions: {
        slotLabelFormat: {hour: 'numeric', minute: '2-digit', hour12: false},
        height: 'auto',
        aspectRatio: 1,
        eventMinHeight: 40,
        selectable: true,
        slotMinWidth: '10',
        eventMinWidth: '5',
        slotMinTime: selectedTimeRange,
        slotMaxTime: '23:59:59',
        resourceAreaWidth: '20%',
        slotDuration: selectedSlotSize,
        resourceOrder: 'weight',
        nowIndicator: true,
        resourcesInitiallyExpanded: true,
        plugins: [ dayGridPlugin, interactionPlugin, resourceTimelinePlugin ],
        initialView: 'resourceTimeline',
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        editable: true,
        eventOrder: "-duration, order",
        headerToolbar: {
          left: '',
          center: '',
          right: ''
        },
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: this.$t('unit')
          }
        ],
        resources: [],
        events: []
      },
      timeRanges: [
        {
          text: this.$t('24hours'),
          value: '00:00:00',
        },
        {
          text: '10 - 24',
          value: '10:00:00',
        },
        {
          text: '17 - 24',
          value: '17:00:00',
        }
      ]
    }
  },
  methods: {
    prevDay() {
      const date = new Date(this.selectedDate)
      this.selectedDate = moment(date.getTime()).subtract(1, 'day').format("YYYY-MM-DD")
    },
    nextDay() {
      const date = new Date(this.selectedDate)
      this.selectedDate = moment(date.getTime()).add(1, 'day').format("YYYY-MM-DD")
    },
    changeSelectedLocation(value) {
      this.selectedLocation = value
    },
    toggleAllLocationVariations() {
      if(this.selectedLocationVariations.length !== this.locationVariationsList.length) {
        this.selectedLocationVariations = this.locationVariationsList.map(locVar => locVar.id)
      } else {
        this.selectedLocationVariations = []
      }
    },
    selectSlots(e) {
      this.selectedSlot = e

      if(this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        this.bookingTime = this.selectedSlot.date.getTime()
      } else if(this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        this.bookingTime = this.selectedSlot.start.getTime()
        if(typeof this.selectedSlot.end !== 'undefined' && this.selectedSlot.end !== null) {
          this.bookingDuration = Math.round(this.selectedSlot.end.getTime() - this.selectedSlot.start.getTime())/60000
        }
      }
    },
    createBooking() {

      if(this.selectedSlot !== null && this.selectedSlot.resource._resource && this.selectedSlot.resource._resource.extendedProps && this.selectedSlot.resource._resource.extendedProps.itemUUID !== undefined) {
        this.bookingItem = this.selectedSlot.resource._resource.extendedProps.itemUUID

        if(this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
          this.bookingTime = this.selectedSlot.date.getTime()
        }
        else if(this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
          this.bookingTime = this.selectedSlot.start.getTime()
        }

        if(this.selectedSlot.end !== null && this.selectedSlot.end !== undefined) {
          this.bookingDuration = Math.round((this.selectedSlot.end.getTime() - this.bookingTime) / 60000)
        }
        else {
          this.bookingDuration = parseInt(this.selectedSlotSize.split(':')[1])
        }
      }

      this.selectedSlot = null

      if(this.bookingTime === null || this.bookingTime === undefined === undefined) {
        this.bookingTime = new Date(this.selectedDate).getTime()
      }

      this.showAddBookingDialog = true
    },
    eventDrop(e) {
      if(e.event._def.extendedProps.bookingUUID) {
        this.eventToUpdate = e
        let oldStartTime = e.oldEvent.startStr
        let oldEndTime = e.oldEvent.endStr
        let newStartTime = e.event.startStr
        let newEndTime = e.event.endStr

        if((oldEndTime !== newEndTime) || (oldStartTime !== newStartTime)) {
          this.showConfirmEventUpdateDialog = true
          return
        }

        let newParentUUID = e.newResource._resource.parentId
        let oldParentUUID = e.oldResource._resource.parentId

        if(newParentUUID.length === 0 && oldParentUUID.length === 0) {
          this.showConfirmEventUpdateDialog = true
          return
        }

        this.calendarOptions.editable = false
        this.updateEvent(this.eventToUpdate)
      }
    },
    eventResize(e) {
      this.eventToUpdate = e
      this.showConfirmEventUpdateDialog = true
    },
    updateEvent(e) {
      this.processing = !this.processing
      const bookingUUID = e.event._def.extendedProps.bookingUUID
      const booking = this.bookings.find(booking => booking.uuid === bookingUUID)

      let resource

      if(e.newResource) {
        resource = e.newResource._resource
      }

      let itemUUID = booking.item.uuid

      if(resource !== undefined && resource !== null && resource.extendedProps !== null && resource.extendedProps.itemUUID !== null && resource.extendedProps.itemUUID !== undefined) {
        itemUUID = resource.extendedProps.itemUUID
      }

      let locationUUID = booking.location && booking.location.uuid ? booking.location.uuid : null
      let locationVariationUUID = booking.locationVariation && booking.locationVariation.uuid ? booking.locationVariation.uuid : null

      if(resource !== undefined && resource !== null && resource.extendedProps && resource.extendedProps.locationUUID !== undefined) {
        locationUUID = resource.extendedProps.locationUUID
      }

      if(resource !== undefined && resource !== null && resource.extendedProps && resource.extendedProps.variationUUID !== undefined) {
        locationVariationUUID = resource.extendedProps.variationUUID + ''
      }
      if(resource !== undefined && resource !== null && resource.extendedProps !== null && resource.extendedProps !== undefined && (resource.extendedProps.variationUUID === null || resource.extendedProps.variationUUID === undefined)) {
        locationVariationUUID = '0'
      }

      const range = e.event._instance.range
      this.$store.dispatch('updateBooking', {
        uuid: booking.uuid,
        startsAtTime: new Date(range.start).getTime() / 1000,
        endsAtTime: Math.round(new Date(range.end).getTime() / 1000),
        item: itemUUID,
        customer: booking.customer.uuid,
        namespace: booking.namespace,
        location: locationUUID,
        locationVariation: locationVariationUUID
      }).finally(() => {
        this.$store.dispatch('getBookingsByDate', {
          startTimeInMillis: this.$store.state.startTimeInMillis,
          endTimeInMillis: this.$store.state.endTimeInMillis
        }).then(() => {
          this.eventToUpdate = null
          this.calendarOptions.editable = true
          this.showConfirmEventUpdateDialog = false
          this.processing = !this.processing
        })
      })
    },
    cancelEventUpdate() {
      this.processing = !this.processing
      this.$store.dispatch('getBookingsByDate', {
        startTimeInMillis: this.$store.state.startTimeInMillis,
        endTimeInMillis: this.$store.state.endTimeInMillis
      }).then(() => {
        this.eventToUpdate = null
        this.calendarOptions.editable = true
        this.showConfirmEventUpdateDialog = false
        this.processing = !this.processing
      })
    },
    blockSlot() {
      if(this.selectedLocation === null || this.selectedLocation === undefined) {
        this.showSelectLocationDialog = true
        return null
      }

      let itemUUID = null

      if(this.selectedSlot.resource._resource && this.selectedSlot.resource._resource.extendedProps && this.selectedSlot.resource._resource.extendedProps.itemUUID !== undefined) {
        itemUUID = this.selectedSlot.resource._resource.extendedProps.itemUUID
      }

      let startTimeInMillis = 0

      if(this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        startTimeInMillis = this.selectedSlot.date.getTime()
      }
      else if(this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        startTimeInMillis = this.selectedSlot.start.getTime()
      }

      let [hours, minutes] = this.selectedSlotSize.split(":")
      hours = parseInt(hours.slice(-1)) * 60
      minutes = parseInt(minutes)

      let durationInMinutes = hours + minutes

      let endTimeInMillis = startTimeInMillis + (durationInMinutes * 60000) -1

      if(this.selectedSlot.end !== null && this.selectedSlot.end !== undefined) {
        endTimeInMillis = this.selectedSlot.end.getTime() - 1
      }

      this.$store.dispatch('blockAvailableTimeSlot', {
        startTimeInMillis: startTimeInMillis,
        endTimeInMillis: endTimeInMillis,
        item: itemUUID,
        location: this.selectedLocation
      }).finally(() => {
        this.$store.dispatch('getBookingsByDate', {
          startTimeInMillis: this.$store.state.startTimeInMillis,
          endTimeInMillis: this.$store.state.endTimeInMillis
        })
      })

      this.selectedSlot = null
      this.showSelectLocationDialog = false
    },
    unblockSlot() {
      const blockedSlot = this.selectedBlockedSlot

      this.$store.dispatch('unblockSelectedTimeSlot', {
        startTimeInMillis: blockedSlot.startTimeInMillis,
        endTimeInMillis: blockedSlot.endTimeInMillis,
        item: blockedSlot.item,
        location: this.selectedLocation
      }).finally(() => {
        this.$store.dispatch('getBookingsByDate', {
          startTimeInMillis: this.$store.state.startTimeInMillis,
          endTimeInMillis: this.$store.state.endTimeInMillis
        })
      })

      this.selectedBlockedSlot = null
    },
    dateClick(e) {
      this.selectedSlot = e

      if(this.selectedSlot.date !== null && this.selectedSlot.date !== undefined) {
        this.bookingTime = this.selectedSlot.date.getTime()
      } else if(this.selectedSlot.start !== null && this.selectedSlot.start !== undefined) {
        this.bookingTime = this.selectedSlot.start.getTime()
      }
    },
    eventClick(e) {
      if(!this.calendarOptions.editable) {
        return
      }

      const bookingUUID = e.event._def.publicId

      this.selectedBooking = this.bookings.find(booking => booking.uuid === bookingUUID)

      const blockedSlot = e.event._def.extendedProps.blockedTimeSlot

      if(blockedSlot && blockedSlot !== undefined) {
        this.selectedBlockedSlot = blockedSlot
      }

      if(e.event._def.extendedProps.blockedTimeSlot !== null) {
        // Do Nothing
      }
    },
    bookingsInItem(itemUUID) {

      if(this.actualBookings !== null) {
        return this.actualBookings.filter(booking => booking.item.uuid === itemUUID).length
      }

      return 0
    },
    paxInItem(itemUUID) {

      let pax = 0

      if(this.actualBookings !== null) {
        for(let i in this.actualBookings) {
          const actualBooking = this.actualBookings[i]

          if(actualBooking.item.uuid !== itemUUID) {
            continue
          }

          pax = pax + actualBooking.quantity
        }
      }

      return pax
    },
    getZonesInLocation(location) {
      const zones = []
      const zoneIndex = []

      if(location.label === '' || location.label === undefined) {
        return zones
      }

      if(location.variations.length === 0) {
        return zones
      }

      const variations = location.variations

      for(let n in variations) {
        const unit = location.variations[n]

        if(unit.zone === null || typeof unit.zone === 'undefined') {
          continue
        }

        if(unit.zone.uuid === '8def7e20-ce3f-4da2-97b3-3deebcded6f2') { //Sjáland Zone
          continue
        }

        if(!zoneIndex.includes(unit.zone.uuid)) {
          zones.push(unit.zone)
          zoneIndex.push(unit.zone.uuid)
        }
      }

      return zones
    },
    getLocationVariationsInZone(location, zone) {
      const variations = location.variations.sort((v1, v2) => {

        var nameA = v1.label.toUpperCase() // ignore upper and lowercase
        var nameB = v2.label.toUpperCase() // ignore upper and lowercase

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })
      return variations.filter(variation => {
        if(variation !== null && variation !== undefined && variation.zone !== null && variation.zone !== undefined) {
          return variation.zone.uuid === zone.uuid
        }
        return false
      }).map(value => {
        return {
          id: value.uuid,
          title: value.label,
          zoneUUID: zone.uuid,
          locationUUID: location.uuid,
          variationUUID: value.uuid
        }
      })
    },
    closeAddBookingsDialog() {
      this.showAddBookingDialog = false
      this.bookingTime = null
      this.bookingDuration = null
      this.bookingItem = null
    },
    rebook(booking) {
      this.bookingItem = booking.item.uuid
      this.bookingDuration = (booking.endsAtTime - booking.startsAtTime) / 60
      this.bookingTime = booking.startsAtTime * 1000
      this.bookingObject = booking
      this.selectedBooking = null
      this.showAddBookingDialog = true
    },
    overwriteBooking(result) {
      this.selectedBooking = result
    }
  },
  computed: {
    isGettingBookings() {
      return this.$store.state.isUpdatingBookings
    },
    selectedDate: {
      set(value) {
        this.$store.commit('updateSelectedDate', value)
      },
      get() {
        return this.$store.state.selectedDate
      }
    },
    selectedLocation: {
      get() {
        return this.$store.state.selectedLocation
      },
      set(value) {
        this.$store.commit('updateSelectedLocation', value)
      }
    },
    selectedBookingState: {
      get() {
        return this.$store.state.selectedBookingState
      },
      set(value) {
        this.$store.commit('updateSelectedBookingState', value)
      }
    },
    bookingStates() {
      return this.$store.state.bookingStates
    },
    organizationUUID() {
      return this.$store.state.organizationUUID
    },
    selectedItemsKey() {
      return this.organizationUUID + ':selectedItems'
    },
    selectedLocationKey() {
      return this.organizationUUID + ':selectedLocation'
    },
    selectedLocationVariationsKey() {
      return this.organizationUUID + ':selectedLocationVariations'
    },
    actualBookings() {
      if(this.bookings !== null && this.bookings !== undefined && this.bookings.length > 0) {
        // return this.bookings.slice().sort((a,b) => {
        //   if(a && b) {
        //     if(a.order < b.order) {
        //       return -1
        //     }
        //     if(a.order > b.order) {
        //       return 1
        //     }
        //   }
        //   return 0
        // })
        return this.bookings
      }
      return []
    },
    bookingsCount() {
      if(this.actualBookings !== null && this.actualBookings !== undefined) {
        return this.actualBookings.length
      }
      return 0
    },
    pax() {
      let pax = 0

      if(this.actualBookings === null || this.actualBookings === undefined) {
        return pax
      }

      for(let i in this.actualBookings) {
        const booking = this.actualBookings[i]
        pax = pax + booking.quantity
      }

      return pax
    },
    paxRedeemed() {
      let pax = 0

      if(this.actualBookings === null || this.actualBookings === undefined) {
        return pax
      }

      for(let i in this.actualBookings) {
        const booking = this.actualBookings[i]

        if(booking.redeemedAt === 0 || booking.redeemedAt === null || booking.redeemedAt === undefined) {
          continue
        }

        pax = pax + booking.quantity
      }

      return pax
    },
    paxRemaining() {
      return this.pax - this.paxRedeemed
    },
    startTimeInMillis() {
      return this.$store.state.startTimeInMillis
    },
    eventItems() {

      if(this.$store.state.items === null || this.$store.state.items === undefined) {
        return []
      }

      let eventItems = this.$store.state.items.filter(item => item.type === 'event' && item.status === true).sort((i1, i2) => i1.title.localeCompare(i2.title))
      eventItems = eventItems.filter(eventItem => eventItem.startDate === 0 || eventItem.endDate === 0 || eventItem.endDate > this.startTimeInMillis)

      if(this.selectedLocation !== null && this.selectedLocation !== undefined) {
        return eventItems.filter(item => typeof item === 'undefined' || item.location === null || item.location.uuid === this.selectedLocation)
      }

      return eventItems
    },
    locationVariationsList() {
      let locationVariations = []
      let index = 0
      for(let i = 0; i < this.locations.length; i++) {
        const location = this.locations[i]

        if(location.label === '' || location.label === undefined) {
          continue
        }
        if(location.variations.length === 0) {
          continue
        }

        const zones = this.getZonesInLocation(location)
        for(let z = 0; z < zones.length; z++) {
          const zone = zones[z]
          const zoneLocationVariations = this.getLocationVariationsInZone(location, zone)
          locationVariations = locationVariations.concat(zoneLocationVariations)
          index++
        }
      }
      return locationVariations
    },
    locations() {
      return this.$store.state.locations.filter(location => location.label !== '' && location.label !== null && location.active === true)
    },
    bookingItems() {
      const items = []

      if(this.actualBookings !== null && this.actualBookings !== undefined) {
        for(let i in this.actualBookings) {
          const booking = this.actualBookings[i]

          let bookingResourceId = booking.item.uuid

          if(booking.locationVariation !== null && booking.locationVariation !== undefined && booking.locationVariation.uuid !== null && booking.locationVariation.uuid !== undefined) {
            bookingResourceId = booking.locationVariation.uuid
          }

          let color = 'green'

          const isRedeemed = booking.redeemedAt ? booking.redeemedAt > 0 : false

          if(isRedeemed) {
            color = 'black'
          }

          if(booking.status === '0') {
            color = 'red'
          }

          //let bookingTitle = booking.customer.address.first_name
          let bookingTitle = ''

          try{
            bookingTitle = booking.customer.address.first_name
            if(booking.customer.address.last_name !== null && booking.customer.address.last_name !== undefined){
              bookingTitle += ' ' + booking.customer.address.last_name
            }
          } catch (e) {
            bookingTitle = this.$t('noName')
          }
          /*
          if(booking.customer.address.last_name !== null) {
            bookingTitle += ' ' + booking.customer.address.last_name.substr(0, 1)
          }
          */
          if(booking.quantity > 1) {
            bookingTitle = '(' + booking.quantity + ') ' + bookingTitle
          }

          if((booking.internalNote !== null && booking.internalNote !== undefined && booking.internalNote !== '') || (booking.externalNote !== null && booking.externalNote !== undefined && booking.externalNote !== '')) {
            bookingTitle = `! ${bookingTitle}`
            if(color === 'green') {
              color = '#196F3D'
            }
          }

          if(booking.locationVariations !== null && booking.locationVariations !== undefined && booking.locationVariations.length > 0) {
            for(let x in booking.locationVariations) {
              const bookedLocationVariation = booking.locationVariations[x]
              items.push({
                id: booking.uuid,
                title: bookingTitle,
                resourceId: bookedLocationVariation.uuid,
                start: new Date(booking.startsAtTime * 1000),
                end: new Date(booking.endsAtTime * 1000),
                bookingUUID: booking.uuid,
                itemUUID: booking.item.uuid,
                color: color,
                order: booking.order
              })
            }
            continue
          }

          items.push({
            id: booking.uuid,
            title: bookingTitle,
            resourceId: bookingResourceId,
            start: new Date(booking.startsAtTime * 1000),
            end: new Date(booking.endsAtTime * 1000),
            bookingUUID: booking.uuid,
            itemUUID: booking.item.uuid,
            color: color,
            order: booking.order
          })
        }
      }

      if(this.blockedTimeSlots !== null && this.blockedTimeSlots !== undefined && this.blockedTimeSlots.length > 0) {
        for(let i in this.blockedTimeSlots) {
          const blockedTimeSlot = this.blockedTimeSlots[i]

          if(blockedTimeSlot === null || blockedTimeSlot === undefined) {
            continue
          }

          if(typeof this.selectedLocation !== 'undefined' || this.selectedLocation !== null) {

            if(!blockedTimeSlot.uuid.includes(this.selectedLocation)) {
              continue
            }

          }

          items.push({
            resourceId: blockedTimeSlot.item,
            start: new Date(blockedTimeSlot.startTimeInMillis),
            end: new Date(blockedTimeSlot.endTimeInMillis),
            rendering: 'background',
            color: 'red',
            blockedTimeSlot: blockedTimeSlot,
            title: this.$t('blocked')
          })
        }
      }

      return items
    },
    resources() {
      const resources = []
      let index = 0

      for(let i in this.eventItems) {
        const eventItem = this.eventItems[i]

        if(this.selectedItems.length > 0 && !this.selectedItems.includes(eventItem.uuid)) {
          continue
        }

        if(eventItem.endDate !== null
          && eventItem.endDate !== undefined
          && eventItem.endDate > 0
          && eventItem.endDate < this.startTimeInMillis
        ) {
          continue
        }

        const bookingPaxCount = this.paxInItem(eventItem.uuid)

        resources.push({
          id: eventItem.uuid,
          title: eventItem.title + ' (' + bookingPaxCount +' PAX)',
          weight: index,
          itemUUID: eventItem.uuid
        })

        index++
      }

      for(let i in this.locations) {

        const location = this.locations[i]

        if(location.label === '' || location.label === undefined) {
          continue
        }

        if(location.variations.length === 0) {
          continue
        }

        const zones = this.getZonesInLocation(location)

        for(let z in zones) {
          const zone = zones[z]
          let locationVariations = this.getLocationVariationsInZone(location, zone)
          if(this.selectedLocationVariations.length > 0) {
            locationVariations = locationVariations.filter(locVar => {
              return this.selectedLocationVariations.includes(locVar.id)
            })
          }
          resources.push({
            id: zone.uuid,
            title: zone.name,
            children: locationVariations,
            weight: index,
            zoneUUID: zone.uuid,
          })
          index++
        }

      }

      return resources
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi()
    },
    blockedTimeSlots() {
      return this.$store.state.blockedTimeSlots
    },
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    }
  },
  watch: {
    selectedAppBarTab(appBarTab) {
      if(appBarTab !== 'list') {
        const _this = this
        setTimeout(
          function() {
            _this.fullCalendarKey += 1
          }, 200
        )
      }
    },
    bookingItems(bookingItems) {
      this.calendarOptions.events = bookingItems
      if(Array.isArray(bookingItems) && bookingItems.length > 0) {
        this.calendarApi.gotoDate(bookingItems[0].start)
      }
    },
    startTimeInMillis(startTimeInMillis) {
      this.calendarApi.gotoDate(new Date(startTimeInMillis))
    },
    selectedItems(selectedItems) {
      this.calendarOptions.resources = this.resources
      localStorage.setItem(this.selectedItemsKey, JSON.stringify(selectedItems))
    },
    selectedLocationVariations(selectedLocationVariations) {
      this.calendarOptions.resources = this.resources
      localStorage.setItem(this.selectedLocationVariationsKey, JSON.stringify(selectedLocationVariations))
    },
    selectedSlotSize(value) {
      this.calendarOptions.slotDuration = value
      localStorage.setItem('slotDuration', value)
    },
    selectedTimeRange(value) {
      this.calendarOptions.slotMinTime = value
      localStorage.setItem('slotMinTime', value)
    },
    selectedLocation(value) {
      localStorage.setItem(this.selectedLocationKey, value)
    },
    bookings() {
      // this.calendarOptions.events = this.bookingItems
      this.calendarOptions.resources = this.resources
    }
  },
  mounted() {
    this.calendarOptions.resources = this.resources
    this.calendarOptions.events = this.bookingItems
    this.calendarOptions.dateClick = this.dateClick
    this.calendarOptions.eventClick = this.eventClick
    this.calendarOptions.eventDrop = this.eventDrop
    this.calendarOptions.eventResize = this.eventResize
    this.calendarOptions.select = this.selectSlots

    if(this.selectedLocation === null || this.selectedLocation === undefined && this.locations.length === 1) {
      for(let i in this.locations) {
        const location = this.locations[i]
        this.selectedLocation = location.uuid
      }
    }

    if(this.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223') {
      this.calendarOptions.slotMinTime = '18:00:00'
      this.calendarOptions.slotMaxTime = '26:59:59'
      this.calendarOptions.slotLabelFormat = {hour: 'numeric', minute: '2-digit', hourCycle: 'h23'}
    }

    if(this.organizationUUID === 'bb78d411-9b7c-4a51-a134-e359cc1bc7f8') {
      this.calendarOptions.slotMinTime = '12:00:00'
      this.calendarOptions.slotMaxTime = '23:59:59'
    }
  }
}
</script>

<style>
table {
  margin-bottom: 0;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}
</style>
